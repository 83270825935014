<template>
	<el-row :gutter="12">
		<el-col :xs="24" :sm="12" :md="12" :lg="6">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card1"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card1-error"
				v-else-if="!loadingCardDashboardData && errorLoadingDashboardCard"
			>
				<div class="text-center">
					<i class="el-icon-warning-outline"></i>
					<h3 class="warning-title fw-bold">Ooops...</h3>
					<p class="warning-description h5">
						Qualcosa è andato storto, Riprova!
					</p>
				</div>
				<div class="text-center mx-auto">
					<el-button type="danger" size="small" @click="tryAgain"
						>Riprova</el-button
					>
				</div>
			</el-card>
			<el-card class="dashboard-card1" :body-style="{ padding: '14px' }" v-else>
				<span class="fs-5 me-1">
					<font-awesome-icon :icon="userTie"></font-awesome-icon>
				</span>
				<span class="fs-5 fw-bold">Fatturato personale</span>
				<p class="ms-2 fs-3 mt-2 text-end">
					€ {{ dashboardData.fatturatoPersonale }}
				</p>
			</el-card>
		</el-col>
		<el-col :xs="24" :sm="12" :md="12" :lg="6">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card1"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card1-error"
				v-else-if="!loadingCardDashboardData && errorLoadingDashboardCard"
			>
				<div class="text-center">
					<i class="el-icon-warning-outline"></i>
					<h3 class="warning-title fw-bold">Ooops...</h3>
					<p class="warning-description h5">
						Qualcosa è andato storto, Riprova!
					</p>
				</div>
				<div class="text-center mx-auto">
					<el-button type="danger" size="small" @click="tryAgain"
						>Riprova</el-button
					>
				</div>
			</el-card>
			<el-card v-else class="dashboard-card1" :body-style="{ padding: '14px' }"
				><span class="fs-5 me-1">
					<font-awesome-icon :icon="groupUser"></font-awesome-icon
				></span>
				<span class="fs-5 fw-bold">Fatturato team</span>
				<p class="ms-2 fs-3 mb-1 mt-2 text-end">
					€ {{ dashboardData.fatturatoTeam }}
				</p>
				<p class="fs-6">
					Fatturato agenti non più attivi: €
					{{ dashboardData.fatturatoInattivo }}
				</p>
			</el-card>
		</el-col>
		<el-col :xs="24" :sm="12" :md="12" :lg="6">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card2"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card2-error"
				v-else-if="!loadingCardDashboardData && errorLoadingDashboardCard"
			>
				<div class="text-center">
					<i class="el-icon-warning-outline"></i>
					<h3 class="warning-title fw-bold">Ooops...</h3>
					<p class="warning-description h5">
						Qualcosa è andato storto, Riprova!
					</p>
				</div>
				<div class="text-center mx-auto">
					<el-button type="danger" size="small" @click="tryAgain"
						>Riprova</el-button
					>
				</div>
			</el-card>
			<el-card v-else class="dashboard-card2" :body-style="{ padding: '14px' }">
				<div class="text-center">
					<span class="fs-5 me-1">
						<font-awesome-icon :icon="handShake"></font-awesome-icon>
					</span>
					<span class="fs-5 fw-bold">Collaboratori diretti</span>
				</div>
				<p class="ms-2 fs-3 mt-2 text-center">
					{{ dashboardData.collaboratoriDiretti }}
				</p>
			</el-card>
		</el-col>
		<el-col :xs="24" :sm="12" :md="12" :lg="6">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card2"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card2-error"
				v-else-if="!loadingCardDashboardData && errorLoadingDashboardCard"
			>
				<div class="text-center">
					<i class="el-icon-warning-outline"></i>
					<h3 class="warning-title fw-bold">Ooops...</h3>
					<p class="warning-description h5">
						Qualcosa è andato storto, Riprova!
					</p>
				</div>
				<div class="text-center mx-auto">
					<el-button type="danger" size="small" @click="tryAgain"
						>Riprova</el-button
					>
				</div>
			</el-card>
			<el-card v-else class="dashboard-card2" :body-style="{ padding: '14px' }">
				<div class="text-center">
					<span class="fs-5">
						<font-awesome-icon :icon="hubSpot"></font-awesome-icon>
					</span>
					<span class="fs-5 fw-bold ms-1">Collaboratori downline</span>
				</div>
				<p class="ms-2 fs-3 mt-2 mb-1 text-center">
					{{ dashboardData.collaboratoriDownline }}
				</p>
				<div class="fs-6 text-end me-3">
					<el-button type="text" @click="handleDialog"
						><font-awesome-icon :icon="info"></font-awesome-icon>
						Dettagli</el-button
					>
				</div>
			</el-card>
		</el-col>
	</el-row>
	<el-row :gutter="12">
		<el-col :xs="24" :sm="24" :md="8" :lg="8">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card3"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card v-else class="dashboard-card3" :body-style="{ padding: '14px' }">
				<span class="fs-5 me-1">
					<font-awesome-icon :icon="clock"></font-awesome-icon>
				</span>
				<span class="fs-5 fw-bold">Fatturato team mese corrente</span>
				<p class="ms-2 fs-3 mt-2 text-end">
					€ {{ dashboardData.fatturatoTeamMese }}
				</p>
				<font-awesome-icon :icon="userClock"></font-awesome-icon>
				<span class="fs-5 fw-bold">Fatturato personale mese corrente</span>
				<p class="ms-2 fs-3 mt-2 text-end">
					€ {{ dashboardData.fatturatoPersonaleMese }}
				</p>
				<span class="fs-5 me-1">
					<font-awesome-icon :icon="check"></font-awesome-icon>
				</span>
				<span class="fs-5 fw-bold">Attivo per percepire le provvigioni</span>
				<p class="ms-2 fs-3 mt-2 text-end">
					€ {{ dashboardData.provvigioniFatturatoAttivazione
					}}<span
						class="ms-2"
						:style="attivazioneProvvigioni ? 'color: green;' : 'color: red;'"
						><font-awesome-icon :icon="circle"></font-awesome-icon>
					</span>
				</p>
			</el-card>
		</el-col>
		<el-col :xs="24" :sm="12" :md="8" :lg="8">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card3"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card v-else class="dashboard-card3" :body-style="{ padding: '14px' }">
				<span class="fs-5 me-1">
					<font-awesome-icon :icon="paid"></font-awesome-icon>
				</span>
				<span class="fs-5 fw-bold">Provvigioni del mese di fatturazione</span>
				<p class="ms-2 fs-3 mt-4 text-end">
					€ {{ dashboardData.provvigioniMeseStoricizzate }}
				</p>
				<p class="mt-5">
					<span class="fs-5 me-1">
						<font-awesome-icon :icon="notpaid"></font-awesome-icon>
					</span>
					<span class="fs-5 fw-bold">Provvigioni sospese</span>
				</p>
				<p class="ms-2 fs-3 mt-3 text-end">
					€ {{ dashboardData.provvigioniMeseSospese }}
				</p>
			</el-card>
		</el-col>
		<el-col :xs="24" :sm="12" :md="8" :lg="8">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card3"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card v-else class="dashboard-card3" :body-style="{ padding: '14px' }">
				<span class="fs-5 me-1">
					<font-awesome-icon :icon="hourglass"></font-awesome-icon>
				</span>
				<span class="fs-5 fw-bold">Provvigioni ancora da pagare</span>
				<p class="ms-2 fs-3 mt-3 text-end">
					€ {{ dashboardData.provvigioniRiporto }}
				</p>
				<p class="fs-5 mt-5">
					IBAN:
					<span :style="iban ? 'color: green;' : 'color: red;'">
						{{ bankAccount.iban }}
						<font-awesome-icon :icon="circle" class="ms-1"></font-awesome-icon
					></span>
					<el-button type="text" @click="handleUpdate" class="ms-1"
						><font-awesome-icon :icon="setting"></font-awesome-icon> Inserisci
						IBAN</el-button
					>
				</p>
			</el-card>
		</el-col>
	</el-row>
	<el-row :gutter="12">
		<el-col :xs="24" :sm="12" :md="12" :lg="12">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card4"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card v-else class="dashboard-card4" :body-style="{ padding: '14px' }">
				<span class="fs-5 me-1">
					<font-awesome-icon :icon="star"></font-awesome-icon>
				</span>
				<span class="fs-5 fw-bold">Qualifica attuale</span>
				<p class="ms-2 fs-5 mt-4 text-center">
					La tua qualifica è:
					<span class="badge bg-primary text-wrap">
						{{ dashboardData.qualifica }}</span
					>
				</p>
				<p class="ms-2 fs-6 mt-4 text-center">
					La tua licenza è {{ dashboardData.statoLicenza }} fino al
					{{ dashboardData.dataScadenzaLicenza }}
				</p>
			</el-card>
		</el-col>
		<el-col :xs="24" :sm="12" :md="12" :lg="12">
			<el-card
				v-loading="loadingCardDashboardData"
				class="dashboard-card4"
				v-if="loadingCardDashboardData"
			>
			</el-card>
			<el-card v-else class="dashboard-card4" :body-style="{ padding: '14px' }">
				<span class="fs-5 me-1">
					<font-awesome-icon :icon="star"></font-awesome-icon>
				</span>
				<span class="fs-5 fw-bold">Regole qualifica</span>
				<div class="ms-2 fs-6 mt-1">
					<font-awesome-icon :icon="info"></font-awesome-icon>
					Fatturato: €
					{{ pianoCarriera.qualificaFatturato }}
				</div>
				<div
					class="ms-2 mt-1"
					v-for="item in pianoCarriera.qualificaCollaboratori"
					:key="item.pkAgente"
				>
					<font-awesome-icon
						:icon="item.item2.nome == null ? userLock : userCheck"
					></font-awesome-icon>
					{{ item.item2.nome }} {{ item.item2.cognome }} ({{
						item.item2.qualifica
					}})
				</div>
			</el-card>
		</el-col>
	</el-row>
	<el-dialog
		v-model="dialogVisibleIBAN"
		:before-close="handleClose"
		:width="device === 'mobile' ? '80%' : '50%'"
		center
	>
		<template #title>
			<span class="fw-bold">Modifica Conto Corrente</span>
		</template>
		<div
			:class="[
				'alert',
				'text-center',
				submitResult ? 'alert-success' : 'alert-danger',
			]"
			v-if="isSubmitted"
		>
			{{ submitResult ? axiosSuccessMessage : axiosErrorMessage }}
		</div>
		<el-form
			show-message
			:label-position="device === 'mobile' ? 'top' : 'left'"
			label-width="120px"
		>
			<el-form-item label="Banca" :error="bancaField.errorMessage">
				<el-input
					type="text"
					@change="handleChange"
					:model-value="bancaField.value"
					@update:model-value="bancaField.handleChange"
				>
				</el-input>
			</el-form-item>
			<el-form-item label="Filiale" :error="filialeField.errorMessage">
				<el-input
					type="text"
					@change="handleChange"
					:model-value="filialeField.value"
					@update:model-value="filialeField.handleChange"
				>
				</el-input>
			</el-form-item>
			<el-form-item label="IBAN" :error="ibanField.errorMessage">
				<el-input
					type="text"
					@change="handleChange"
					:model-value="ibanField.value"
					@update:model-value="ibanField.handleChange"
				>
				</el-input>
			</el-form-item>
			<el-form-item label="ABI" :error="abiField.errorMessage">
				<el-input
					type="text"
					@change="handleChange"
					:model-value="abiField.value"
					@update:model-value="abiField.handleChange"
				>
				</el-input>
			</el-form-item>
			<el-form-item label="ABI" :error="abiField.errorMessage">
				<el-input
					type="text"
					@change="handleChange"
					:model-value="abiField.value"
					@update:model-value="abiField.handleChange"
				>
				</el-input>
			</el-form-item>
			<el-form-item label="CAB" :error="cabField.errorMessage">
				<el-input
					type="text"
					@change="handleChange"
					:model-value="cabField.value"
					@update:model-value="cabField.handleChange"
				>
				</el-input>
			</el-form-item>
			<el-form-item label="Conto" :error="contoField.errorMessage">
				<el-input
					type="text"
					@change="handleChange"
					:model-value="contoField.value"
					@update:model-value="contoField.handleChange"
				>
				</el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button type="info" @click="handleClose"> Indietro </el-button>
				<el-button :loading="loading" type="primary" @click="submitForm">
					Conferma
				</el-button>
			</span></template
		>
	</el-dialog>

	<el-dialog
		v-model="dialogVisible"
		:width="device === 'mobile' ? '90%' : '30%'"
		center
	>
		<template #title>
			<span class="fw-bold">Collaboratori Downline</span>
		</template>
		<div>
			<div class="row justify-content-center">
				<div class="col" style="height: 500px; overflow: scroll">
					<el-tree
						default-expand-all
						:data="treeData"
						node-key="id"
						:expand-on-click-node="true"
					>
						<template #default="{ node }">
							<span class="custom-tree-node">
								<span>{{ node.label }}</span>
							</span>
						</template>
					</el-tree>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import { useField, useForm } from 'vee-validate';
import { UpdateBankAccount } from '@/api/modifyData.js';
import { reactive } from 'vue';
import * as yup from 'yup';
import { mapState } from 'vuex';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
	faUsers,
	faUserTie,
	faHandshake,
	faClock,
	faStar,
	faStepForward,
	faInfoCircle,
	faUserCheck,
	faUserLock,
	faFlagCheckered,
	faCircle,
	faEuroSign,
	faHourglassStart,
	faUserCog,
	faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import {
	faCreativeCommonsNcEu,
	faHubspot,
} from '@fortawesome/free-brands-svg-icons';
import {
	FetchTree,
	FetchDashboard,
	FetchPianoCarriera,
	FetchBankAccount,
} from '@/api/fetchData.js';
export default {
	components: { FontAwesomeIcon },
	computed: {
		...mapState({
			device: (state) => state.app.device,
		}),
	},
	setup() {
		const { meta: formMeta, handleSubmit, resetForm, handleReset } = useForm();
		const bancaField = reactive(
			useField('banca', yup.string().required('Campo richiesto').nullable())
		);
		const filialeField = reactive(useField('filiale', yup.string().nullable()));
		const ibanField = reactive(
			useField('iban', yup.string().required('Campo richiesto').nullable())
		);
		const abiField = reactive(useField('abi', yup.string().nullable()));
		const cabField = reactive(useField('cab', yup.string().nullable()));
		const contoField = reactive(useField('conto', yup.string().nullable()));
		return {
			formMeta,
			handleSubmit,
			resetForm,
			handleReset,
			bancaField,
			filialeField,
			abiField,
			ibanField,
			cabField,
			contoField,
		};
	},
	data() {
		return {
			groupUser: faUsers,
			userTie: faUserTie,
			handShake: faHandshake,
			hubSpot: faHubspot,
			clock: faClock,
			star: faStar,
			next: faStepForward,
			info: faInfoCircle,
			userLock: faUserLock,
			userCheck: faUserCheck,
			notpaid: faCreativeCommonsNcEu,
			paid: faEuroSign,
			check: faFlagCheckered,
			circle: faCircle,
			hourglass: faHourglassStart,
			setting: faUserCog,
			userClock: faUserClock,
			dialogVisibleIBAN: false,
			dialogVisible: false,
			treeData: null,
			dashboardData: {},
			pianoCarriera: {},
			loading: false,
			loadingCardPianoCarriera: false,
			loadingCardDashboardData: false,
			attivazioneProvvigioni: false,
			bankAccount: [],
			iban: false,
			formModified: false,
			isSubmitted: false,
			submitResult: false,
			axiosErrorMessage: '',
			axiosSuccessMessage: '',
			submitForm: this.handleSubmit(this.onSubmit),
			errorLoadingDashboardCard: false,
			errorLoadingPianoCarrieraCard: false,
		};
	},
	created() {
		this.fetchTree();
		this.fetchDashboard();
		this.fetchPianoCarriera();
		this.fetchBankAccount();
	},
	methods: {
		async onSubmit(formValues) {
			this.loading = true;
			this.isSubmitted = false;
			await UpdateBankAccount(formValues)
				.then(() => {
					this.isSubmitted = true;
					this.submitResult = true;
					this.axiosSuccessMessage = 'Dati aggiornati con successo';
					this.formModified = false;
					this.fetchBankAccount();
				})
				.catch((error) => {
					this.isSubmitted = true;
					this.submitResult = false;
					this.axiosErrorMessage = 'Aggiornamento dati fallito';
					let keys = Object.keys(error.response.data.errors);
					if (keys.indexOf('banca') !== -1) {
						this.bancaField.errors.push(error.response.data.errors.banca[0]);
					}
					if (keys.indexOf('filiale') !== -1) {
						this.filialeField.errors.push(
							error.response.data.errors.filiale[0]
						);
					}
					if (keys.indexOf('iban') !== -1) {
						this.ibanField.errors.push(error.response.data.errors.iban[0]);
					}
					if (keys.indexOf('abi') !== -1) {
						this.abiField.errors.push(error.response.data.errors.abi[0]);
					}
					if (keys.indexOf('cab') !== -1) {
						this.cabField.errors.push(error.response.data.errors.cab[0]);
					}
					if (keys.indexOf('conto') !== -1) {
						this.contoField.errors.push(error.response.data.errors.conto[0]);
					}
				});
			this.loading = false;
		},
		handleUpdate() {
			this.dialogVisibleIBAN = true;
			this.initForm();
		},
		initForm() {
			this.isSubmitted = false;
			this.bancaField.value = this.bankAccount.banca;
			this.filialeField.value = this.bankAccount.filiale;
			this.ibanField.value = this.bankAccount.iban;
			this.abiField.value = this.bankAccount.abi;
			this.cabField.value = this.bankAccount.cab;
			this.contoField.value = this.bankAccount.conto;
		},
		handleClose(done) {
			if (this.formModified == true) {
				this.$confirm('Sei sicuro di voler chiudere?')
					.then(() => {
						done;
						this.dialogVisibleIBAN = false;

						this.formModified = false;
					})
					.catch(() => {});
			} else {
				this.dialogVisibleIBAN = false;
			}
		},
		handleChange() {
			this.formModified = true;
			this.isSubmitted = false;
		},
		async fetchBankAccount() {
			await FetchBankAccount().then((response) => {
				this.bankAccount = response.data;
				if (this.bankAccount.iban != null) {
					this.iban = true;
				}
			});
		},
		handleDialogIBAN() {
			this.dialogVisibleIBAN = true;
		},
		handleDialog() {
			this.dialogVisible = true;
		},
		async fetchPianoCarriera() {
			this.loadingCardPianoCarriera = true;
			await FetchPianoCarriera()
				.then((response) => {
					this.pianoCarriera = response.data;
				})
				.catch(() => {
					this.errorLoadingPianoCarrieraCard = true;
				});
			this.loadingCardPianoCarriera = false;
		},
		async fetchDashboard() {
			this.loadingCardDashboardData = true;
			await FetchDashboard()
				.then((response) => {
					this.dashboardData = response.data;
					this.dashboardData.dataScadenzaLicenza = moment(
						this.dashboardData.dataScadenzaLicenza
					).format('DD/MM/YYYY');
					this.dashboardData.fatturatoInattivo =
						this.dashboardData.fatturatoInattivo.toFixed(2);
					this.dashboardData.fatturatoPersonale =
						this.dashboardData.fatturatoPersonale.toFixed(2);
					this.dashboardData.fatturatoTeam =
						this.dashboardData.fatturatoTeam.toFixed(2);
					this.dashboardData.fatturatoTeamMese =
						this.dashboardData.fatturatoTeamMese.toFixed(2);
					this.dashboardData.fatturatoPersonaleMese =
						this.dashboardData.fatturatoPersonaleMese.toFixed(2);
					this.attivazioneProvvigioni =
						this.dashboardData.provvigioniFatturatoAttivazione > 30;
				})
				.catch(() => {
					this.errorLoadingDashboardCard = true;
				});
			this.loadingCardDashboardData = false;
		},
		async fetchTree() {
			await FetchTree().then((response) => {
				this.treeData = [response.data];

				this.treeData = JSON.parse(JSON.stringify(this.treeData));
			});
		},
	},
};
</script>

<style scoped>
.dashboard-card1 {
	height: 160px;
	margin-bottom: 5px;
	background-color: #fce9da;
	border-color: #d36a14;
}
.dashboard-card1-error {
	height: 250px;
	margin-bottom: 3px;
	background-color: #fce9da;
	border-color: #d36a14;
}

.dashboard-card2 {
	height: 160px;
	margin-bottom: 5px;
	background-color: #dafce1;
	border-color: #13d43a;
}
.dashboard-card2-error {
	height: 250px;
	margin-bottom: 5px;
	background-color: #dafce1;
	border-color: #13d43a;
}

.dashboard-card3 {
	height: 375px;
	margin-bottom: 5px;
	background-color: #f8dafc;
	border-color: #bd13d4;
}

.dashboard-card4 {
	height: 210px;
	margin-bottom: 5px;
	background-color: rgba(0, 100, 255, 0.1);
	border-color: #247ba0;
}
.warning-description {
	color: #ff4949;
	padding: 4px;
}
.warning-title {
	color: #ff4949;
	padding: 4px;
}
.el-icon-warning-outline {
	color: #ff4949;
	font-size: 75px;
}
</style>
